body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.backinstockDiv {
  border-radius: 2px;
  max-width: 600px;
  width: 100%;
}

.backinstock-main {
  padding: 15px;
  margin-top: 10px;
}

.backinstock-title {
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  margin-bottom: 15px;
}

.backinstock-title h3 {
  text-transform: none;
  margin: 0px;
  padding: 0px;
}

.backinstock-description {
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
  line-height: 20px;
  font-size: 14px;
}

.backinstock-emailaddress {
  margin-top: 10px;
}

.backinstock-email-input {
  padding: 8px 15px;
  width: 100%;
  border-radius: 5px;
  border-color: #000000;
  border-width: 2px
}

.backinstock-email-error {
  color: red;
  display: none;
  font-size: 12px;
  margin-top: 2px;
  padding-left: 3px;
}

.backinstock-submit-btn {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
  border-radius: 5px;
  width: 100%;
  margin: 10px 0 10px 0;
  text-align: center;
}

.backinstock-popup-btn {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
  border-radius: 5px;
  width: 100%;
  text-align: center;
}

.backinstock-footer {
  font-size: small;
}

.backinstock-newstock {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.newstock_div {
  box-shadow: 0px 0px 4px 0px #00000040;
  border-radius: 4px;
  border: 2px;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.div-selected {
  border: 2px solid #165DBA
}

.divider {
  border: 1px solid #00000040;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block !important;
}

.backinstock-footerdiv {
  font-size: 12px;
  text-align: center;
}

.modal {
  display: none;
  position: fixed;
  z-index: 99;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  width: 25% !important;
  border: none !important;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-right: 20px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

#backinstock-success-msg{
  font-size: 13px;
  padding: 5px;
  padding-left: 10px;
  border-radius: 5px;
}

#backinstockRoot .backinstock-main{
  margin-top: 0 !important;
}

.select-box{
  min-width: 100%;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}

.select-box select {
  appearance: none;
  width: 100%;
  padding: 0.675em 6em 0.675em 1em;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
}


.select-box::before,
.select-box::after {
  --size: 0.3rem;
  content: "";
  position: absolute;
  right: 1rem;
  pointer-events: none;
}

.select-box::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid black;
  top: 40%;
}

.select-box::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid black;
  top: 55%;
}
